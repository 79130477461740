import '@/shared/assets/scss/styles.scss'
import '@/shared/assets/css/fonts.css'
import '@/shared/assets/css/tailwind.css'
import '@/shared/assets/css/animation.css'
import '@/shared/assets/css/skeleton.css'
import '@/shared/assets/css/html.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { sentryPlugin } from '@/shared/plugins/sentry'
import { api } from '@/shared/plugins/api'
import { i18nPlugin } from '@/shared/plugins/i18n'
import { modalPlugin } from '@/shared/plugins/modal'
import { analyticsPlugin } from '@/shared/plugins/analytics'
import { gtagPlugin } from '@/shared/plugins/gtag'
import { amplitudePlugin } from '@/shared/plugins/amplitude'
import 'webrtc-adapter'

import App from './App.vue'
import router from './router'

const app = createApp(App)

// @ts-ignore
app.use(createPinia())
// @ts-ignore
app.use(router)
sentryPlugin(app, router)
app.use(i18nPlugin())
app.use(api.apiPlugin)
app.use(modalPlugin)
app.use(amplitudePlugin)
app.use(gtagPlugin)
app.use(analyticsPlugin)

app.mount('#app')
